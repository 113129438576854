// src/components/Home.tsx
import React from 'react';

const Home: React.FC = () => {
  return <h2>Home</h2>;
};

export default Home;

// Add an empty export statement to make this file a module
export {};